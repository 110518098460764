import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 17; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/22-nov-1/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  for (let i = 1; i <= 9; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/22-nov-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function JoyOfGiving2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">JOY OF GIVING - A SMALL STEP, A BIG SMILE</h1>

        <p>
          <strong>
            “ We make a living by what we get, but we make a life by what we
            give. Only a life lived for others is a life worthwhile.”
          </strong>{" "}
          <br />
          <br />
          Chinmaya Vidyalalya, New Delhi, as a part of its 'Joy of Giving'
          initiative took a small step of 'Each One Donate One' activity that
          made the students embrace the spirit of service. The students from
          classes I-VIII happily donated dry ration items and the little
          learners of Pre-Primary contributed diyas,candles and sweets.The
          contributions were handed over to the Earth Saviour Foundation,
          temples and facility staff.
          <br />
          <br />
          The students of Primary Department gifted painted and decorated diyas,
          handmade paper lanterns and torans to their helpers at home. The
          students participated enthusiastically in all the activities and
          learnt that true happiness lies in giving, and not getting.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
